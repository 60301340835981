<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">
          <h1 class="text-h4">{{ $lang.routes.credentials }}</h1>
          <div class="d-inline-flex align-center">
            <v-switch v-model="activeOnly" data-cy="switch-active-only" :label="$lang.labels.activeOnly" class="pl-5 pr-3"></v-switch>
            <v-text-field
              v-model="search"
              outlined
              clearable
              hide-details
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
            <v-select
              v-model="selectedType"
              class="pl-2"
              hide-details
              :items="typesFormatted"
              :label="$lang.labels.type"
              outlined
              dense
              clearable
            ></v-select>
            <div>
              <v-btn
                text
                light
                class="ml-2"
                color="primary"
                @click="$emit('fetchCredentials', { options: options, search: search && search.length > 1 ? search : '', type: selectedType, resetPage: true })"
              >
                <v-icon
                  dense
                  small
                >mdi-refresh</v-icon>
              </v-btn>
            </div>
            <v-btn
              v-if="roles.includes('CREDENTIAL_CREATOR')"
              color="accent"
              class="color-accent-text mr-1"
              @click="showTypeSelector = true"
            >{{ $lang.actions.create }}
            </v-btn>
          </div>

        </div>
        <v-divider class="mt-3"></v-divider>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.status.YES : $lang.status.NO }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn
            color="secondary"
            data-cy="credential-activation"
            class="mx-1 button-default-width"
            @click="$emit('changeStatus', {id: item.id, status: item.status})"
          >
            {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
          </v-btn>
          <v-btn
            color="primary"
            class="mx-0 button-default-width"
            :to="{ name: 'credentialEdit', params: { id: item.id } }"
          >{{ $lang.actions.edit }}
          </v-btn>
          <v-btn
            v-if="roles.includes('CREDENTIAL_CREATOR')"
            color="accent"
            class="ml-1 button-default-width color-accent-text"
            @click="copyCreateResource(item, 'credentialCreate', $router, item.type.toLowerCase(), $options.filters.formatDateTime(new Date()))"
          >{{ $lang.actions.copyCreate }}</v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-if="showTypeSelector" v-model="showTypeSelector" max-width="30%">
      <create-dialog-modal
        :types="credentialTypes"
        @create="createCredential"
        @closeDialog="showTypeSelector = false"
      ></create-dialog-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import CreateDialogModal from '../../components/ui/modals/CreateDialogModal'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'

export default {
  components: {
    CreateDialogModal
  },
  props: {
    items: {
      type: Object,
      default: () => {
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.system, value: 'isSystem', sortable: true },
        { text: this.$lang.header.type, value: 'type' },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      credentialTypes: definitions.CreateCredentialFields.properties.type.enum.sort(),
      selectedType: '',
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings']),
    typesFormatted() {
      return this.credentialTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.lock) this.$emit('fetchCredentials', {
          options: this.options,
          search: this.search && this.search.length > 1 ? this.search : '',
          type: this.selectedType,
          resetPage: false
        })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler(val) {
        if (val && val.length > 1 && !this.lock) this.$emit('fetchCredentials', {
          options: this.options,
          search: val,
          type: this.selectedType,
          resetPage: true
        })
        if (!this.lock && (val === null || val === '')) this.$emit('fetchCredentials', {
          options: this.options,
          search: '',
          type: this.selectedType,
          resetPage: true
        })
        this.options.page = 1
        this.savePreFill()
      }
    },
    activeOnly: {
      handler(val) {
        if (!this.lock) this.$emit('activeOnly', {
          activeOnly: val,
          options: {
            options: this.options,
            search: this.search && this.search.length > 1 ? this.search : '',
            type: this.selectedType,
            resetPage: true
          }
        })
        this.options.page = 1
        this.savePreFill()
      }
    },
    selectedType: {
      handler() {
        if (!this.lock) this.$emit('fetchCredentials', {
          options: this.options,
          search: this.search && this.search.length > 1 ? this.search : '',
          type: this.selectedType,
          resetPage: true
        })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchCredentials', {
        options: this.options,
        search: this.search && this.search.length > 1 ? this.search : '',
        type: this.selectedType,
        resetPage: true
      })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', class: 'created-on' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.credentialList) {
        this.search = preFill.credentialList.search
        this.activeOnly = preFill.credentialList.activeOnly
        this.selectedType = preFill.credentialList.selectedType
        this.options = preFill.credentialList.options

        this.$emit('activeOnly', {
          activeOnly: this.activeOnly,
          options: {
            options: this.options,
            search: this.search && this.search.length > 1 ? this.search : '',
            type: this.selectedType
          }
        })
      } else {
        this.$emit('fetchCredentials', {
          options: this.options,
          search: this.search && this.search.length > 1 ? this.search : '',
          type: this.selectedType,
          resetPage: true
        })
        this.options.page = 1
      }
    } else {
      this.$emit('fetchCredentials', {
        options: this.options,
        search: this.search && this.search.length > 1 ? this.search : '',
        type: this.selectedType,
        resetPage: true
      })
      this.options.page = 1
    }
    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    copyCreateResource,
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.credentialList) preFill.credentialList = {
          search: '',
          activeOnly: true,
          selectedType: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.credentialList.search = this.search
        preFill.credentialList.activeOnly = this.activeOnly
        preFill.credentialList.selectedType = this.selectedType
        preFill.credentialList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    createCredential(type) {
      this.showTypeSelector = false
      this.$router.push({ name: 'credentialCreate', params: { type: type.toLowerCase() } })
    }
  }
}
</script>
